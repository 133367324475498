<template>
  <div data-cy="scraper-list">
    <PageHeaderDisplay
      title="Connectors"
      sub-title="Manage third-party connectors and integrations."
      category="Automation"
      category-logo="fa-conveyor-belt"
      :category-route="{ name: $route.name }"
    />
    <Spinner v-if="loading" />
    <TabView v-else route-name="connectors" :tabs="tabs" :exact="false">
      <router-view />
    </TabView>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import PageHeaderDisplay from '@/components/PageHeaderDisplay';
import TabView from '@/components/TabView';
import Spinner from '@/components/Spinner';

export default {
  name: 'ScraperView',
  components: {
    PageHeaderDisplay,
    Spinner,
    TabView
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapGetters({
      accounts: 'account/accounts',
      company: 'company/company'
    }),
    tabs() {
      return [
        {
          name: 'dashboard',
          route: 'connectors-dashboard',
          icon: 'fa-tachometer'
        },
        {
          name: 'Crawler Runs',
          route: 'connectors-runs',
          icon: 'fa-clock-rotate-left'
        },
        {
          name: 'Manage',
          route: 'connectors-manage',
          icon: 'fa-cog'
        }
      ];
    }
  },
  async mounted() {
    if (this.$router.currentRoute.name === 'connectors') this.$router.push({ name: 'connectors-dashboard' });

    await this.getCompany({ id: this.$auth.companyId });

    await this.listAccounts({
      data: {
        params: {
          parentAccountId: 'null',
          $populate: 'asset,asset.entity,company',
          $limit: 100000,
          $select: [
            'assetId',
            'companyId',
            'supplierId',
            'type',
            'name',
            'portal',
            'supplierRef',
            'meterSerialNumber',
            'automaticMeterRead',
            'capacity',
            'meterOperator',
            'dataCollector',
            'meterPointNumber',
            'asset.siteName',
            'asset.entityId',
            'asset.address',
            'entity.legalName',
            'company.name'
          ].join(',')
        }
      }
    });

    if (!this.accounts || this.accounts.length === 0) return [];

    const suppliersByKey = {};

    const accounts = [...this.accounts];

    accounts.sort((a, b) => a.assetId - b.assetId);

    accounts
      .filter(a => a.supplier)
      .forEach(a => {
        if (suppliersByKey[a.supplier._id]) {
          suppliersByKey[a.supplier._id].accounts.push(a);
        } else {
          suppliersByKey[a.supplier._id] = {
            ...a.supplier,
            accounts: [a]
          };
        }
      });

    const suppliers = Object.values(suppliersByKey);

    suppliers.sort((a, b) => a.name.localeCompare(b.name));

    const filteredSuppliers = suppliers.map(s => {
      const companyPortalSettings = (this.company.settings.supplierPortals || []).find(sp => sp.supplierId === s._id);

      const isGloballyEnabled = s.portal?.scraperEnabled;
      const isCompanyEnabled = companyPortalSettings?.portal ? companyPortalSettings.portal.scraperEnabled : false;
      const isAnyAccountEnabled = s.accounts.some(a => a.portal && a.portal.scraperEnabled);

      return {
        ...s,
        isEnabled: isGloballyEnabled || isCompanyEnabled || isAnyAccountEnabled,
        isGloballyEnabled,
        isCompanyEnabled,
        isAnyAccountEnabled
      };
    });

    this.setSuppliers(filteredSuppliers);

    this.loading = false;
  },
  methods: {
    ...mapActions({
      listAccounts: 'account/list',
      getCompany: 'company/get'
    }),
    ...mapMutations({
      setSuppliers: 'scraperRun/SET_SUPPLIERS'
    })
  }
};
</script>
